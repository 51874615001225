/**
 * A structure containing information about a service or networking error.
 */
export class AWSError extends Error {
  /**
   * A unique short code representing the error that was emitted.
   */
  public code: string;
  /**
   * A longer human readable error message.
   */
  public message: string;
  /**
   * Whether the error message is retryable.
   */
  public retryable: boolean;
  /**
   * In the case of a request that reached the service, this value contains the response status code.
   */
  public statusCode: number;
  /**
   * The date time object when the error occurred.
   */
  public time: Date;
  /**
   * Set when a networking error occurs to easily identify the endpoint of the request.
   */
  public hostname: string;
  /**
   * Set when a networking error occurs to easily identify the region of the request.
   */
  public region: string;
  /**
   * Amount of time (in seconds) that the request waited before being resent.
   */
  public retryDelay: number;
  /**
   * The unique request ID associated with the response.
   */
  public requestId: string;
  /**
   * Second request ID associated with the response from S3.
   */
  public extendedRequestId: string;
  /**
   * CloudFront request ID associated with the response.
   */
  public cfId: string;
}
