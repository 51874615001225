export class PhoneNumbers {
  /**
   * Expects a ten numeric character string that can include extra non-numeric characters.
   * Returns the provided phone number in the format that UBA expects.
   *
   * Ex: given: '9999999999'
   *     returns: '999-999-9999'
   *
   * @param phoneNumber
   * @returns The last ten digits of the provided phone number.
   */
  public static formatPhoneNumberForUBA(phoneNumber: string): string {
    const phoneDigits = PhoneNumbers.stripNonNumericCharacters(phoneNumber);
    const formattedPhoneNumber = `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(3, 6)}-${phoneDigits.slice(6, 10)}`;

    return formattedPhoneNumber;
  }

  /**
   * Expects a ten numeric character string that can include extra non-numeric characters.
   * Returns the provided phone number in the format that Amazon Cognito expects.
   *
   * Ex: given: '999-999-9999'
   *     returns: '+19999999999'
   *
   * @param phoneNumber
   * @returns The environment code plus last ten digits of the provided phone number.
   */
  public static formatPhoneNumberForCognito(phoneNumber: string): string {
    const phoneDigits = PhoneNumbers.stripNonNumericCharacters(phoneNumber);
    const formattedPhoneNumber = `+1${phoneDigits}`;

    return formattedPhoneNumber;
  }

  private static stripNonNumericCharacters(phoneNumber: string): string {
    let phoneDigits = phoneNumber.replace(/[^0-9\*]/g, '');
    // remove U.S. country code if present
    if (phoneDigits.length === 11 && (phoneDigits[0] === '1' || phoneDigits[0] === '*')) {
      phoneDigits = phoneDigits.slice(1);
    }

    if (phoneDigits.length < 10) {
      throw new Error('Phone number provided with less than 10 numeric digits');
    } else if (phoneDigits.length > 10) {
      throw new Error('Phone number provided with more than 10 numeric digits');
    }

    return phoneDigits;
  }
}
