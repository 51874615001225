export interface ConfirmUserAttributeResult {
  additionalInfo?: string;
  error?: Error;
  type: ConfirmUserAttributeResultType;
}

export enum ConfirmUserAttributeResultType {
  CodeExpired = 'CodeExpired',
  Failed = 'Failed',
  IncorrectCode = 'IncorrectCode',
  Success = 'Success',
  UnhandledErrorCode = 'UnhandledErrorCode',
  Unsupported = 'Unsupported',
}
