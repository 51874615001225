export interface ConfirmForgotPasswordResult {
  error?: Error;
  type: ConfirmForgotPasswordResultType;
}

export enum ConfirmForgotPasswordResultType {
  CodeExpired = 'CodeExpired',
  Failed = 'Failed',
  IncorrectCode = 'IncorrectCode',
  Success = 'Success',
  UnhandledErrorCode = 'UnhandledErrorCode',
  UserNotFound = 'UserNotFound',
}
