import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { BankAccountState } from './bank-account.query';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BankAccount' })
export class BankAccountStore extends EntityStore<BankAccountState> {
  public constructor() {
    super();
  }
}
