// AUTOGENERATED BY FUNCTION genubamodel.js
export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export interface RuleCriteria {
  key?: string;
  value?: string;
  ruleMatchType?: RuleMatchType;
  ruleChainType?: RuleChainType;
}

export interface CommunicationPreferenceCommand extends CommandBase {
  data?: CommunicationPreference;
}

export interface CommunicationRegistryCommand extends CommandBase {
  data?: CommunicationRegistry;
}

export interface CommunicationRequestCommand extends CommandBase {
  data?: CommunicationRequest;
  additionalProperties?: AdditionalLetterProperties;
}

export interface CommunicationTemplateCommand extends CommandBase {
  data?: CommunicationTemplate;
  letterTemplateBytes?: string;
}

export interface CommunicationTypeCommand extends CommandBase {
  data?: CommunicationType;
}

export interface EventConfigurationCommand extends CommandBase {
  data?: EventConfiguration;
}

export interface UnsubscribedClientCommand extends CommandBase {
  data?: UnsubscribedClient;
}

export interface MessageCommand extends CommandBase {
  data?: Message;
}

export interface ProcessEventCommand extends CommandBase {
  data?: ProcessEvent;
}

export interface ScheduleEventCommand extends CommandBase {
  data?: ScheduleEvent;
}

export interface TemplateCommand extends CommandBase {
  data?: Template;
}

export interface LetterTemplatePreview {
  communicationTemplatePayloadType?: CommunicationTemplatePayloadType;
  letterTemplateBytes?: string;
  includeOptionalData?: string;
  tpaPartner?: string;
}

export interface LetterRequestCommand extends CommandBase {
  data?: LetterRequest;
  additionalProperties?: AdditionalLetterProperties;
}

export interface AdditionalLetterProperties {
  selectedPAGIds?: string[];
  workOrderNumber?: string;
  informationRequiredToProcess?: string;
  participantName?: string;
  showElectionPacketResend?: boolean;
  pastObligationPrices?: ObligationPricesPayload[];
  futureObligationPrices?: ObligationPricesPayload[];
  timelineEffectiveDate?: string;
  originalDocumentId?: string;
  futureObligationStartDate?: string;
  totalAmountDue?: string;
  remainingAmountDue?: string;
}

export interface CommunicationPreference extends EntityBase {
  communicationCategory: string;
  IsEmailPreference?: boolean;
  IsSMSPreference?: boolean;
  IsAppNotifyPreference?: boolean;
  ccRecipients?: string[];
}

export interface CommunicationRegistry extends EntityBase {
  communicationRequestType: CommunicationRequestType;
  serviceOfferingId: string;
  copyToOverrides?: object;
  eligibilityEventSelections?: string[];
}

export interface CommunicationRequest extends EntityBase {
  communicationRequestType: CommunicationRequestType;
  numberOfDocumentsToGenerate?: number;
  numberOfMessagesToSend?: number;
  recipientId: string;
}

export interface CommunicationType extends EntityBase {
  communicationPackage: CommunicationPackageType[];
  name?: string;
  copyToSpouse?: boolean;
  copyToDomesticPartner?: boolean;
  copyToDependents?: boolean;
  recipientType?: CommunicationRecipientType[];
  requiredChannels?: DistributionChannel[];
  optionalChannels?: DistributionChannel[];
}

export interface DeliveryStatus {
  created?: string;
  status?: MessageStatusType;
}

export interface DistributionMethod {
  isSecured: boolean;
  tascCommCenter: TASCCommCenter;
  email?: EmailTemplate;
  sms?: SMS;
  usMail?: USMail;
}

export interface DistributionChannel {
  channel: DistributionMethodType;
  defaultSendAction?: DefaultSendAction;
}

export interface EmailTemplate {
  isSelected: boolean;
  messageTemplateId: string;
}

export interface EventConfiguration extends EntityBase {
  name?: string;
  entityType: string;
  triggerType: string;
  eventType: EventType;
  communicationCategoryType?: string;
  description: string;
  ruleCriterias?: RuleCriteria[];
}

export interface UnsubscribedClient extends EntityBase {
  clientId: string;
}

export interface EventPayload {
  id?: string;
  recipientId?: string;
  recipientType?: RecipientType;
  entityName?: string;
  entityData?: string;
  clientId?: string;
  templateId?: string;
  communicationCategory?: string;
  fromEmail?: string;
  sendOutboudMessages?: boolean;
}

export interface Message extends EntityBase {
  recipientId: string;
  recipientType: RecipientType;
  tpaPartner?: TPAPartnerType;
  recipientName?: string;
  subject?: string;
  activityId: string;
  distributionMethodType: DistributionMethodType;
  messageTrackingId: string;
  deliveryPayload?: DeliveryPayload;
  formattedFileId?: string;
  formattedMessageText?: string;
  messageContentInfo?: MessageContentInfo;
  deliveryStatuses?: DeliveryStatus[];
}

export interface MessageContentInfo {
  content?: string;
  dataFields?: VariableDataField[];
  CcInfo?: CcInfo;
  tpaPartner?: TPAPartnerType;
}

export interface CcInfo {
  ccAllowed?: boolean;
  preferenceCategory?: string;
}

export interface VariableDataField {
  name?: string;
  value?: string;
}

export interface DeliveryPayload {
  email?: EmailPayload;
  sms?: SMSPayload;
}

export interface EmailPayload {
  to: string[];
  cc?: string[];
}

export interface SMSPayload {
  phone: string[];
}

export interface MessageContent extends Message {
  messageContent?: string;
}

export interface MessageTemplate {
  templateId?: string;
  messageTemplateContents?: MessageTemplateContent[];
}

export interface MessageTemplateContent {
  id?: string;
  name?: string;
  versions?: MessageTemplateContentVersion[];
}

export interface MessageTemplateContentVersion {
  id?: string;
  active?: boolean;
  name?: string;
  htmlContent?: string;
  plainContent?: string;
  subject?: string;
}

export interface ProcessEvent extends EntityBase {
  eventId?: string;
  eventPayload?: EventPayload;
}

export interface ScheduleEvent extends EntityBase {
  scheduleEventType?: ScheduleEventType;
}

export interface SendEmail {
  from: EmailRecipientType;
  replyTo?: EmailRecipientType;
  to: EmailRecipientType[];
  cc?: EmailRecipientType[];
  bcc?: EmailRecipientType[];
  subject: string;
  content?: EmailContentType[];
  attachments?: EmailAttachmentType[];
  messageTemplateId?: string;
  substitutions?: EmailSubstitutionType[];
  messageTrackingId?: string;
}

export interface SMS {
  isSelected: boolean;
  messageText: string;
}

export interface TASCCommCenter {
  messageTemplateId: string;
}

export interface USMail {
  isSelected: boolean;
  messageTemplateId: string;
}

export interface CommunicationTemplate extends EntityBase {
  templateType: CommunicationTemplateType;
  communicationTypeId?: string;
  communicationChannel?: DistributionMethodType;
  letterTemplateServiceId?: string;
  name: string;
  publishDate: string;
  payloadType?: CommunicationTemplatePayloadType;
  payloadPaths?: CommunicationTemplatePayloadPathsType;
  templateDefinition?: object;
  stateSpecific?: string[];
  fileName?: string;
  messageText?: string;
  coPackage?: boolean;
  clientId?: string;
  clientClassIds?: number[];
  simplex?: boolean;
}

export interface CommunicationPayload {
  payloadType?: CommunicationTemplatePayloadType;
  payload?: string;
}

export interface LetterRequest extends EntityBase {
  documentId?: string;
  letterServiceRequestId: string;
  letterTemplateVersionId?: string;
  letterTemplateId: string;
  letterRequestType: LetterRequestType;
  profileId?: string;
  profileType?: LetterRequestProfileType;
  mailToProfileId?: string;
  mailToProfileType?: LetterRequestProfileType;
}

export interface DataMapper extends EntityBase {
  category?: string;
  name?: string;
  description?: string;
  dataType?: DataMapperDataType;
  inputEntityType?: DataMapperEntityType;
  fieldValueNotation?: string;
  fieldType?: DataMapperFieldType;
  format?: string;
  serviceDomain?: DataMapperDomain;
  dataSource?: string;
  referenceEntity?: DataMapperEntityType;
  referenceEntityFieldName?: string;
}

export interface AddressPayload {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface TPAPayload {
  companyName: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  phone?: string;
  logoImage: string;
}

export interface UtilityPayloadWrapper {
  utility: UtilityPayload;
}

export interface IndividualProfilePayload extends BasicIndividualPayload {
  id: string;
  externalId: string;
  temporaryPasscode?: string;
  className?: string;
}

export interface ClientProfilePayload extends BasicClientPayload {
  id: string;
  externalId: string;
  address: AddressWithPhonePayload;
}

export interface AdditionalProfilePropertiesPayload {
  workOrderNumber?: string;
  informationRequiredToProcess?: string;
  participantName?: string;
}

export interface MailToPayload {
  blockName: string;
  recipient: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface PaymentRate {
  dateOfRateChange: string;
  creditAmount: string;
}

export interface CheckRejectionPayload {
  backedOutDate: string;
  checkAmount: string;
  checkNumber: string;
  paymentDeadline: string;
}

export interface UnderPaidPayload {
  fundingAccountBalance: string;
  totalAmountDue: string;
  remainingAmountDue: string;
}

export interface ProfilePayload {
  individual: IndividualProfilePayload;
  client: ClientProfilePayload;
  dependent: DependentPayload[];
  utility: UtilityPayload;
  mailTo: MailToPayload;
  futureObligationPrices?: ObligationPricesPayload[];
  pastObligationPrices?: ObligationPricesPayload[];
  futureStartDate?: string;
  creditAmount?: string;
  additionalProperties?: AdditionalProfilePropertiesPayload;
}

export interface ProfilePayloadWrapper {
  profile: ProfilePayload;
}

export interface AddressWithPhonePayload extends AddressPayload {
  phone?: string;
}

export interface IndividualPaymentPayload extends BasicIndividualPayload {
  externalId: string;
}

export interface IndividualElectionPayload extends BasicIndividualPayload {
  externalId?: string;
  individualDateOfBirth: string;
  temporaryPasscode: string;
}

export interface BasicIndividualPayload {
  individualName: string;
  address: AddressWithPhonePayload;
  className?: string;
}

export interface BasicClientPayload {
  clientName: string;
  tpaPartner: string;
}

export interface DependentPayload {
  id: string;
  dependentName: string;
  dateOfBirth: string;
  relationship: string;
  address?: AddressPayload;
}

export interface EligibilityEventPayload {
  eventType: string;
  eventDate: string;
  childLosingCoverage: string;
}

export interface PlanPricesPayload {
  planName: string;
  lossOfCoverageDate: string;
  eligibilityStartDate: string;
  eligibilityEndDate: string;
  tier: string;
  totalPrice: string;
  adjustmentPrice: string;
  adjustmentStartDate: string;
  adjustmentEndDate: string;
  electionDueDate: string;
  paidThroughDate: string;
  conversionContactName?: string;
  conversionContactInfo?: string;
}

export interface CoveredPartiesPayload {
  profileName: string;
}

export interface ContinuationElectionPayload {
  planName: string;
  coveredParties: object[];
}

export interface ObligationPricesPayload {
  planName: string;
  coverageLevel: string;
  paidThroughDate: string;
  dueDate: string;
  premium: string;
  initialDueDate: string;
  initialPaymentAmount: string;
  eligibilityEndDate: string;
  inactivationDate: string;
  conversionContactName?: string;
  conversionContactInfo?: string;
  startDate?: string;
}

export interface InactivatedPlansPayload {
  planName: string;
  coverageLevel: string;
  inactivationReason?: string;
  inactivationDate: string;
}

export interface ConversionPlansPayload {
  planName: string;
  coverageLevel: string;
  eligibilityEndDate: string;
  conversionContactName?: string;
  conversionContactInfo: string;
}

export interface UtilityPayload {
  address: MailToPayload;
  mergedDate: string;
  salutation: string;
  companyName: string;
  companyAddress: string;
  companyCity: string;
  companyState: string;
  companyZip: string;
  companyWebsite: string;
  companyFax: string;
  companyDepartmentName: string;
  companyCsrName: string;
  companyCsrPhone: string;
  companyCsrHours: string;
  paymentCompany: string;
  paymentAddress: string;
  paymentCityStateZip: string;
  logoImage: string;
  pageBreakHtml: string;
}

export interface ElectionPayload {
  individual: IndividualElectionPayload;
  client: BasicClientPayload;
  spouses?: DependentPayload[];
  otherDependents?: DependentPayload[];
  eligibilityEvent: EligibilityEventPayload;
  planPrices?: PlanPricesPayload[];
  continuationElection?: ContinuationElectionPayload[];
  utility: UtilityPayload;
  mailTo: MailToPayload;
  showElectionPacketResend: boolean;
}

export interface ElectionPayloadWrapper {
  election: ElectionPayload;
}

export interface PaymentPayloadWrapper {
  payment: PaymentPayload;
}

export interface InactivationPayloadWrapper {
  inactivation?: InactivationPayload;
}

export interface ConversionPayloadWrapper {
  conversion: ConversionPayload;
}

export interface PaymentPayload {
  individual: IndividualPaymentPayload;
  client: BasicClientPayload;
  obligationPrices?: ObligationPricesPayload[];
  anticipatedObligationPrices?: ObligationPricesPayload[];
  futureObligationPrices?: ObligationPricesPayload[];
  utility: UtilityPayload;
  mailTo: MailToPayload;
  rate: PaymentRate;
  underPaid?: UnderPaidPayload;
  initialDay: string;
  couponHtml?: string;
  datePlusFifteen: string;
}

export interface InactivationPayload {
  individual: BasicIndividualPayload;
  client: BasicClientPayload;
  inactivatedPlans: InactivatedPlansPayload[];
  utility: UtilityPayload;
  mailTo: MailToPayload;
}

export interface ConversionPayload {
  individual: BasicIndividualPayload;
  client: BasicClientPayload;
  conversionPlans: ConversionPlansPayload[];
  utility: UtilityPayload;
  mailTo: MailToPayload;
}

export interface PaymentRejectionPayloadWrapper {
  paymentRejection: PaymentRejectionPayload;
}

export interface PaymentRejectionPayload {
  individual: BasicIndividualPayload;
  client: BasicClientPayload;
  rejection: CheckRejectionPayload;
  utility: UtilityPayload;
  mailTo: MailToPayload;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export type SearchCriteria = Criteria[];

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export interface Template extends EntityBase {
  templateName: string;
  communicationCategory: string;
  triggerId: string;
  recipientType: RecipientType;
  tpaPartner: TPAPartnerType;
  accountOfferings?: string[];
  clientBusinessFunctions?: string[];
  distributionMethod?: DistributionMethod;
  allowCCRecipient?: boolean;
  clientId?: string;
  sendToPrimary?: boolean;
}

export type CommunicationPreferences = CommunicationPreference[];

export type EventConfigurations = EventConfiguration[];

export type UnsubscribedClients = UnsubscribedClient[];

export interface EmailAttachmentType {
  content: string;
  type?: string;
  fileName: string;
}

export interface EmailContentType {
  type: string;
  value: string;
}

export interface EmailRecipientType {
  email: string;
  name?: string;
}

export interface EmailSubstitutionType {
  key: string;
  value: string;
}

export interface CommunicationTemplatePayloadPathsType {
  nodes?: string[];
  markup?: string[];
}

export interface ReceiverConfigurationType {
  client?: boolean;
  individual?: IndividualLetterReceiverType[];
}

export enum ParentType {
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  CLIENT_CONTACT = 'CLIENT_CONTACT',
  EVENT_CONFIGURATION = 'EVENT_CONFIGURATION',
  INSTANCE = 'INSTANCE',
  BENEFIT_PLAN = 'BENEFIT_PLAN',
  ELIGIBILITY_EVENT = 'ELIGIBILITY_EVENT',
  ELIGIBILITY_EVENT_OFFERING = 'ELIGIBILITY_EVENT_OFFERING',
  EMPLOYMENT_INFO = 'EMPLOYMENT_INFO',
  DOCUMENT = 'DOCUMENT',
  MSP_REPORTING_REQUEST = 'MSP_REPORTING_REQUEST',
  BUREAU = 'BUREAU',
  MSP_REPORTING_FILE = 'MSP_REPORTING_FILE',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  COMMUNICATION_TYPE = 'COMMUNICATION_TYPE',
  ENTRY = 'ENTRY',
}

export enum CommunicationPreferenceCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
}

export enum CommunicationRegistryCommandType {
  StartToActive = 'StartToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum CommunicationRequestCommandType {
  StartToRequested = 'StartToRequested',
  RequestedToProcessing = 'RequestedToProcessing',
  ProcessingToProcessed = 'ProcessingToProcessed',
  ProcessingToNotRequired = 'ProcessingToNotRequired',
  ProcessingToError = 'ProcessingToError',
}

export enum CommunicationTypeCommandType {
  ActiveToActive = 'ActiveToActive',
  StartToActive = 'StartToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum EventConfigurationCommandType {
  StartToInActive = 'StartToInActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInActive = 'ActiveToInActive',
  InActiveToActive = 'InActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  InActiveToRemoved = 'InActiveToRemoved',
  InActiveToInActive = 'InActiveToInActive',
}

export enum UnsubscribedClientCommandType {
  StartToActive = 'StartToActive',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToActive = 'InactiveToActive',
}

export enum MessageCommandType {
  StartToFailed = 'StartToFailed',
  StartToSent = 'StartToSent',
  StartToDelivered = 'StartToDelivered',
  SentToDelivered = 'SentToDelivered',
  SentToFailed = 'SentToFailed',
  DeliveredToRead = 'DeliveredToRead',
}

export enum ProcessEventCommandType {
  StartToProcessed = 'StartToProcessed',
}

export enum ScheduleEventCommandType {
  StartToScheduled = 'StartToScheduled',
}

export enum TemplateCommandType {
  StartToInitiated = 'StartToInitiated',
  StartToActive = 'StartToActive',
  InitiatedToInitiated = 'InitiatedToInitiated',
  InitiatedToActive = 'InitiatedToActive',
  InitiatedToRemoved = 'InitiatedToRemoved',
  ActiveToActive = 'ActiveToActive',
  ActiveToArchived = 'ActiveToArchived',
  ArchivedToArchived = 'ArchivedToArchived',
  ArchivedToActive = 'ArchivedToActive',
}

export enum CommunicationTemplateCommandType {
  StartToDraft = 'StartToDraft',
  DraftToDraft = 'DraftToDraft',
  InactiveToDraft = 'InactiveToDraft',
  DraftToPublished = 'DraftToPublished',
  AnyToInactive = 'AnyToInactive',
}

export enum LetterRequestCommandType {
  StartToSubmitted = 'StartToSubmitted',
  StartToResubmitted = 'StartToResubmitted',
  SubmittedToReady = 'SubmittedToReady',
  ResubmittedToMailPending = 'ResubmittedToMailPending',
  AnyToError = 'AnyToError',
  SubmittedToMailPending = 'SubmittedToMailPending',
  MailPendingToReady = 'MailPendingToReady',
}

export enum EventType {
  Triggered = 'Triggered',
  Scheduled = 'Scheduled',
}

export enum ScheduleEventType {
  CardExpiry = 'CardExpiry',
  PlanYearEnd1 = 'PlanYearEnd1',
  PlanYearEnd2a = 'PlanYearEnd2a',
  PlanYearEnd2b = 'PlanYearEnd2b',
  PlanYearEnd3 = 'PlanYearEnd3',
  PlanRenewalLeadTime90 = 'PlanRenewalLeadTime90',
  PlanRenewalLeadTime60 = 'PlanRenewalLeadTime60',
  PlanRenewalLeadTime45 = 'PlanRenewalLeadTime45',
  PlanRenewalLeadTime30 = 'PlanRenewalLeadTime30',
  PlanRenewalLeadTime15 = 'PlanRenewalLeadTime15',
  PlanRenewalFollowupTime15 = 'PlanRenewalFollowupTime15',
  PendingEnrollmentApproval = 'PendingEnrollmentApproval',
  VerifyPlan = 'VerifyPlan',
  IncompleteEnrollment = 'IncompleteEnrollment',
  AutoACH = 'AutoACH',
  ClientACHFunding = 'ClientACHFunding',
}

export enum DataMapperEntityType {
  BenefitAccount = 'BenefitAccount',
  BenefitPlan = 'BenefitPlan',
  Contact = 'Contact',
  Client = 'Client',
  Entry = 'Entry',
  Individual = 'Individual',
  Posting = 'Posting',
}

export enum DataMapperFieldType {
  API = 'API',
  DataHandler = 'DataHandler',
  Deeplink = 'Deeplink',
}

export enum DataMapperDataType {
  String = 'String',
  Boolean = 'Boolean',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  Currency = 'Currency',
}

export enum DataMapperDomain {
  Profile = 'Profile',
  Configuration = 'Configuration',
}

export enum DefaultSendAction {
  GenerateAndMail = 'GenerateAndMail',
  Generate = 'Generate',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum RuleMatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum RuleChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum CommunicationPackageType {
  CobraCommunications = 'CobraCommunications',
  CommonwealthOfMa = 'CommonwealthOfMa',
  Conversion = 'Conversion',
  ElectionPaymentReminder = 'ElectionPaymentReminder',
  FinalPaymentReminder = 'FinalPaymentReminder',
  GeneralInitialNotice = 'GeneralInitialNotice',
  NormalPaymentReminder = 'NormalPaymentReminder',
  Pre65 = 'Pre65',
  RetireeBillingCommunications = 'RetireeBillingCommunications',
}

export enum CommunicationRecipientType {
  INDIVIDUAL = 'INDIVIDUAL',
  CLIENT = 'CLIENT',
}

export enum CommunicationRequestType {
  ClientCancellation = 'ClientCancellation',
  ElectionOffer = 'ElectionOffer',
  EndOfAdjustment = 'EndOfAdjustment',
  FinalObligation = 'FinalObligation',
  FirstLateObligation = 'FirstLateObligation',
  GeneralInitialNotice = 'GeneralInitialNotice',
  LateObligation = 'LateObligation',
  ACHNonSufficientFunds = 'ACHNonSufficientFunds',
  CheckNonSufficientFunds = 'CheckNonSufficientFunds',
  FourEightyEight = 'FourEightyEight',
  IndividualCancellation = 'IndividualCancellation',
  PlanConversion = 'PlanConversion',
  UnderPaid = 'UnderPaid',
  ACHAuthorization = 'ACHAuthorization',
  UnavailableFSA = 'UnavailableFSA',
  MissingDependentInformation = 'MissingDependentInformation',
  ElectionPacketPassword = 'ElectionPacketPassword',
  ACHConfirmation = 'ACHConfirmation',
  RateChange = 'RateChange',
  SLA = 'SLA',
  FinalContactRates = 'FinalContactRates',
  FinalContactQE = 'FinalContactQE',
  FinalContactTQB = 'FinalContactTQB',
  AppealDenied = 'AppealDenied',
  AppealAccepted = 'AppealAccepted',
  CouponCover = 'CouponCover',
  Pre65 = 'Pre65',
  MigrationPassword = 'MigrationPassword',
  NewClient = 'NewClient',
  CoverageChange = 'CoverageChange',
}

export enum DistributionMethodType {
  TASCMessageCenter = 'TASCMessageCenter',
  Email = 'Email',
  SMS = 'SMS',
  USMail = 'USMail',
  PushNotification = 'PushNotification',
}

export enum EventConfigurationState {
  Active = 'Active',
  InActive = 'InActive',
  Removed = 'Removed',
  Start = 'Start',
}

export enum CommunicationTemplateState {
  Draft = 'Draft',
  Published = 'Published',
  Inactive = 'Inactive',
}

export enum CommunicationRegistryState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum CommunicationTypeState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum CommunicationRequestState {
  Requested = 'Requested',
  Processing = 'Processing',
  NotRequired = 'NotRequired',
  Processed = 'Processed',
  Error = 'Error',
}

export enum UnsubscribedClientState {
  Active = 'Active',
  Inactive = 'Inactive',
  Start = 'Start',
}

export enum ScheduleEventState {
  Start = 'Start',
  Scheduled = 'Scheduled',
}

export enum MessageStatusType {
  Start = 'Start',
  Sent = 'Sent',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Resent = 'Resent',
  Read = 'Read',
}

export enum TemplateStatusType {
  Initiated = 'Initiated',
  Active = 'Active',
  Archived = 'Archived',
  Removed = 'Removed',
}

export enum RecipientType {
  Individual = 'Individual',
  Client = 'Client',
}

export enum TPAPartnerType {
  TASC = 'TASC',
  BASIC = 'BASIC',
}

export enum CommunicationPreferenceStatusType {
  Active = 'Active',
}

export enum CommunicationTemplatePayloadType {
  Payment = 'Payment',
  PaymentRejection = 'PaymentRejection',
  Profile = 'Profile',
  Utility = 'Utility',
  Election = 'Election',
  Inactivation = 'Inactivation',
  Conversion = 'Conversion',
}

export enum CommunicationTemplateType {
  Letter = 'Letter',
  PushNotification = 'PushNotification',
}

export enum IndividualLetterReceiverType {
  Spouse = 'Spouse',
  DomesticPartner = 'DomesticPartner',
  ChildOther = 'ChildOther',
}

export enum LetterRequestType {
  Generate = 'Generate',
  GenerateAndMail = 'GenerateAndMail',
  Mail = 'Mail',
}

export enum LetterRequestProfileType {
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  CLIENT = 'CLIENT',
  ELIGIBILITY_EVENT = 'ELIGIBILITY_EVENT',
  ENTRY = 'ENTRY',
}

export enum LetterRequestState {
  Submitted = 'Submitted',
  Resubmitted = 'Resubmitted',
  Ready = 'Ready',
  Error = 'Error',
  MailPending = 'MailPending',
}
