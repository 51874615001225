export interface VerifyUserAttributeResult {
  additionalInfo?: string;
  error?: Error;
  type: VerifyUserAttributeResultType;
}

export enum VerifyUserAttributeResultType {
  Failed = 'Failed',
  Success = 'Success',
  UnhandledErrorCode = 'UnhandledErrorCode',
}
