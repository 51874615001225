export interface ChangeUserAttributeResult {
  additionalInfo?: string;
  error?: Error;
  type: ChangeUserAttributeResultType;
}

export enum ChangeUserAttributeResultType {
  Failed = 'Failed',
  Success = 'Success',
  Unsupported = 'Unsupported',
}
