export interface FederatedSignInResult {
  error?: Error;
  type: FederatedSignInResultType;
}

export enum FederatedSignInResultType {
  Failed = 'Failed',
  InvalidSession = 'InvalidSession',
  DifferentUserReturned = 'DifferentUserReturned',
  Success = 'Success',
}
