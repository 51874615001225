export interface ForgotPasswordResult {
  additionalInfo?: string;
  error?: Error;
  type: ForgotPasswordResultType;
}

export enum ForgotPasswordResultType {
  ConfirmationCodeSentToEmail = 'ConfirmationCodeSentToEmail',
  ConfirmationCodeSentToPhone = 'ConfirmationCodeSentToPhone',
  ConfirmationCodeSentToUnsupportedDevice = 'ConfirmationCodeSentToUnsupportedDevice',
  Failed = 'Failed',
  InvalidEmailAddress = 'InvalidEmailAddress',
  LimitExceeded = 'LimitExceeded',
  UnhandledErrorCode = 'UnhandledErrorCode',
  Unsupported = 'Unsupported',
}
