import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DocumentAction } from 'src/app/shared/models/uba/profileConfiguration/model';

export interface DocumentActionState extends EntityState<DocumentAction, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'DocumentAction' })
export class DocumentActionStore extends EntityStore<DocumentActionState> {
  public constructor() {
    super();
  }
}
