// AUTOGENERATED BY FUNCTION genubamodel.js
export interface AttachmentCommand extends CommandBase {
  data?: Attachment;
  sourceURI?: string;
}

export interface FileBatchCommand extends CommandBase {
  data?: FileBatch;
}

export interface FileBuilderCommand extends CommandBase {
  data?: FileBuilder;
}

export interface FileBuilderPartCommand extends CommandBase {
  data?: FileBuilderPart;
}

export interface FileBuilderPostProcessorCommand extends CommandBase {
  data?: FileBuilderPostProcessor;
}

export interface FileBuilderProcessorCommand extends CommandBase {
  data?: FileBuilderProcessor;
}

export interface FileInfoCommand extends CommandBase {
  data?: FileInfo;
}

export interface FileLineCommand extends CommandBase {
  data?: FileLine;
  previousResponse?: object;
}

export interface FileProcessConfigCommand extends CommandBase {
  data?: FileProcessConfig;
}

export interface FileWriterCommand extends CommandBase {
  data?: FileWriter;
}

export interface ManualFileCommand extends CommandBase {
  data?: ManualFile;
}

export interface Attachment extends EntityBase {
  filePath?: string;
  attachmentType?: string;
  friendlyFileName?: string;
  configuration?: AttachementConfig;
}

export interface AttachementConfig {
  convertToImage?: boolean;
  generateThumbnail?: boolean;
  copyToPublicBucket?: boolean;
}

export interface FileAttachmentMetadata {
  fileAttachmentId?: string;
  fileAttachmentType?: string;
  parentId?: string;
  parentType?: ParentType;
  name?: string;
}

export interface FileAttachmentUrl {
  s3Url?: string;
}

export interface FileBatch extends EntityBase {
  awsRequestId: string;
  successfulRows: number;
  failedRows: number;
}

export interface FileBuilder extends EntityBase {
  fileBuilderType: FileBuilderType;
  fileContentData: object;
}

export interface FileBuilderPart extends EntityBase {
  baseFilePath?: string;
  data?: string;
  fileCreated?: string;
  bucket?: string;
  fileType?: FileBuilderType;
  externalReferenceId?: string;
}

export interface FileBuilderPostProcessor extends EntityBase {
  baseFilePath?: string;
  data?: string;
  fileCreated?: string;
  bucket?: string;
  fileType?: FileBuilderPostProcessorType;
  externalReferenceId?: string;
  fileName?: string;
  key?: string;
  manualFileId?: string;
  attachmentFileId?: string;
  fileDestination?: string;
}

export interface FileBuilderGroup {
  baseFilePath?: string;
  fileType?: FileBuilderType;
  created?: string;
}

export interface FileBuilderProcessor extends EntityBase {
  fileName?: string;
  createdFrom?: string;
  createdTo?: string;
  fileType?: string;
  delimiter?: string;
  header?: string;
  footer?: string;
}

export interface FileDownloadInfo {
  createdDate?: string;
  modifyDateTime?: string;
  name?: string;
  fullPath?: string;
  type?: string;
  url?: string;
  tpaPartner?: string;
}

export interface FileLine extends EntityBase {
  fileType?: FileType;
  isTest?: boolean;
  lineData?: string[];
  record?: object;
  args?: object;
  lineNumber: number;
  lastStep?: string;
  steps?: string[];
  individualExternalId?: string;
  individualEmployeeId?: string;
  errors?: FileMessage[];
}

export interface FileInfo extends EntityBase {
  fileName: string;
  fileType: FileType;
  fileLocation?: string;
  isTest?: boolean;
  jobId?: string;
  messages?: FileMessage[];
  hasWarnings?: boolean;
  headers?: string[];
  numberOfRecordsProcessed?: number;
  successfulRecordCount?: number;
  totalRecordCount?: number;
}

export interface FileStats {
  numberProcessed?: string;
  numberError?: string;
  numberWarning?: string;
  numberCancelled?: string;
}

export interface FileSummary {
  fileId?: string;
  stats?: FileStats;
  exceededThresholds?: string[];
}

export interface FileProcessConfig extends EntityBase {
  fileType: FileType;
  allowed?: boolean;
  processingType?: ProcessingType;
  config?: FileProcessConfigExtra;
}

export interface FileSearchCriteria {
  startDate: string;
  endDate: string;
  fileRequestType: FileRequestType;
  tpaPartner?: string;
}

export interface TradeFileBuilder extends EntityBase {
  paymentSourceId?: string;
  amount?: number;
  type?: TradeFileBuilderType;
  fileName?: string;
  externalReferenceId?: string;
}

export interface CheckFileBuilder extends EntityBase {
  checkDate?: string;
  checkVoidDate?: string;
  checkNumber?: string;
  checkAmount?: string;
  customerId?: string;
  customerName?: string;
  description?: string;
  paidAmount?: string;
  bankAccountNumber?: string;
  bankRoutingNumber?: string;
  bankName?: string;
  fracRouting?: string;
  checkMemo?: string;
  payeeName1?: string;
  payeeName2?: string;
  payeeAddress1?: string;
  payeeAddress2?: string;
  payeeCity?: string;
  payeeState?: string;
  payeePostalCode?: string;
  letterBody?: string;
  paymentSourceId?: string;
  externalReferenceId?: string;
}

export interface ClearingFileBuilder extends EntityBase {
  entryId?: string;
  amount?: number;
  type?: ClearingFileEntryType;
  externalReferenceId?: string;
}

export interface FileWriter extends EntityBase {
  fileOutputData: string;
  fileFolder: string;
  fileBucket?: string;
  delimiter?: string;
  header?: string;
}

export interface ManualFile extends EntityBase {
  fileName: string;
  type?: ManualFileType;
  status?: ManualFileStatus;
  statusChangedDateTime?: string;
  comments?: string;
  documentAttachmentId?: string;
}

export interface FileMessage {
  data?: string;
  field?: string;
  message?: string;
  severity?: SeverityLevel;
}

export interface FileProcessConfigExtra {
  claimConnex?: ClaimConnexConfig;
  census?: CensusConfig;
  crossroads?: CrossroadsConfig;
  enrollment?: EnrollmentConfig;
  postingVerification?: PvrConfig;
  dependent?: DependentConfig;
  takeover?: TakeoverConfig;
  event?: EventConfig;
  generalInitialNotice?: GeneralInitialNoticeConfig;
}

export interface CensusConfig {
  protectedColumns?: string[];
  protectedFields?: string[];
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface CrossroadsConfig {
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface EnrollmentConfig {
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface PvrConfig {
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface DependentConfig {
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface TakeoverConfig {
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface ClaimConnexConfig {
  payToProvider?: boolean;
  ccxStartDate?: string;
  ccxEndDate?: string;
  carrierConfigs?: ClaimConnexCarrierConfig[];
  claimConnexType?: ClaimConnexType;
}

export interface ClaimConnexCarrierConfig {
  clientCarrierId?: string;
  clientCarrierName?: string;
  clientCarrierEin?: string;
  startDate?: string;
  endDate?: string;
  medCodes?: ClaimConnexMedCode[];
  groupIds?: string[];
}

export interface EventConfig {
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface GeneralInitialNoticeConfig {
  threshold?: { [key: string]: number };
  thresholdLineCount?: number;
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export type SearchCriteria = Criteria[];

export type Attachments = Attachment[];

export type FileBatches = FileBatch[];

export type FileDownloadInfos = FileDownloadInfo[];

export type FileLines = FileLine[];

export type FileInfos = FileInfo[];

export type FileProcessConfigs = FileProcessConfig[];

export type ManualFiles = ManualFile[];

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum AttachmentCommandType {
  LinkedToStored = 'LinkedToStored',
  StartToUploaded = 'StartToUploaded',
  StoredToLinked = 'StoredToLinked',
  StoredToRemoved = 'StoredToRemoved',
  UploadedToStored = 'UploadedToStored',
}

export enum FileBatchCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
}

export enum FileBuilderCommandType {
  StartToProcessed = 'StartToProcessed',
}

export enum FileBuilderPartCommandType {
  StartToPending = 'StartToPending',
  PendingToFileProcessed = 'PendingToFileProcessed',
}

export enum FileBuilderPostProcessorCommandType {
  StartToProcessed = 'StartToProcessed',
}

export enum FileBuilderProcessorCommandType {
  StartToProcessing = 'StartToProcessing',
}

export enum FileInfoCommandType {
  StartToPending = 'StartToPending',
  PendingToProcessing = 'PendingToProcessing',
  PendingToError = 'PendingToError',
  ProcessingToProcessing = 'ProcessingToProcessing',
  ProcessingToProcessed = 'ProcessingToProcessed',
  ProcessingToProcessedWithErrors = 'ProcessingToProcessedWithErrors',
  ProcessingToError = 'ProcessingToError',
  PendingToCancelling = 'PendingToCancelling',
  ProcessingToCancelling = 'ProcessingToCancelling',
  PendingToCancelled = 'PendingToCancelled',
  ProcessingToCancelled = 'ProcessingToCancelled',
  ProcessingToActionRequired = 'ProcessingToActionRequired',
  ActionRequiredToProcessing = 'ActionRequiredToProcessing',
  ActionRequiredToCancelling = 'ActionRequiredToCancelling',
  ActionRequiredToCancelled = 'ActionRequiredToCancelled',
}

export enum FileLineCommandType {
  ActiveToActive = 'ActiveToActive',
  ProcessingToComplete = 'ProcessingToComplete',
  ProcessingToError = 'ProcessingToError',
  ProcessingToFailed = 'ProcessingToFailed',
  ProcessingToProcessing = 'ProcessingToProcessing',
  StartToActive = 'StartToActive',
  StartToFailed = 'StartToFailed',
  StartToProcessing = 'StartToProcessing',
}

export enum FileProcessConfigCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
}

export enum FileWriterCommandType {
  StartToProcessed = 'StartToProcessed',
}

export enum ManualFileCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
}

export enum AttachmentState {
  Linked = 'Linked',
  Removed = 'Removed',
  Uploaded = 'Uploaded',
  Start = 'Start',
  Stored = 'Stored',
}

export enum FileBuilderPartState {
  Pending = 'Pending',
  Processing = 'Processing',
  FileProcessed = 'FileProcessed',
  Error = 'Error',
}

export enum FileBatchState {
  Active = 'Active',
  Start = 'Start',
}

export enum FileInfoState {
  Pending = 'Pending',
  Processing = 'Processing',
  Processed = 'Processed',
  ProcessedWithErrors = 'ProcessedWithErrors',
  Error = 'Error',
  Start = 'Start',
  Cancelled = 'Cancelled',
  ActionRequired = 'ActionRequired',
}

export enum FileLineState {
  Active = 'Active',
  Complete = 'Complete',
  Error = 'Error',
  Failed = 'Failed',
  Processing = 'Processing',
  Start = 'Start',
}

export enum FileProcessConfigState {
  Active = 'Active',
  Start = 'Start',
}

export enum ParentType {
  CLIENT = 'CLIENT',
  DHA = 'DHA',
  INDIVIDUAL = 'INDIVIDUAL',
  FILEINFO = 'FILEINFO',
  INSTANCE = 'INSTANCE',
  PAYEE = 'PAYEE',
  PYAB = 'PYAB',
  REQUEST = 'REQUEST',
  DOCUMENT = 'DOCUMENT',
  CUSTOMIZATION = 'CUSTOMIZATION',
  CARD_PACKAGE = 'CARD_PACKAGE',
  SUPPORT_REQUEST = 'SUPPORT_REQUEST',
  UHA = 'UHA',
}

export enum FileBuilderType {
  TradeFile = 'TradeFile',
  ClearingFile = 'ClearingFile',
  CheckFile = 'CheckFile',
  TradeFileV2 = 'TradeFileV2',
}

export enum FileBuilderPartType {
  TradeFile = 'TradeFile',
  ClearingFile = 'ClearingFile',
  CheckFile = 'CheckFile',
}

export enum FileBuilderPostProcessorType {
  TradeFile = 'TradeFile',
  ClearingFile = 'ClearingFile',
  CheckFile = 'CheckFile',
}

export enum TradeFileBuilderType {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum ClearingFileEntryType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum FileType {
  CcxEnrollment = 'CcxEnrollment',
  CcxSubstantiation = 'CcxSubstantiation',
  CxcClaimMatch = 'CxcClaimMatch',
  Census = 'Census',
  Crossroads = 'Crossroads',
  ClaimConnex = 'ClaimConnex',
  Dependent = 'Dependent',
  Enrollment = 'Enrollment',
  GuideStarSeed = 'GuideStarSeed',
  PositiveBalance = 'PositiveBalance',
  PostingVerification = 'PostingVerification',
  PreCheckDisbursement = 'PreCheckDisbursement',
  Takeover = 'Takeover',
  TradeResponse = 'TradeResponse',
  Request = 'Request',
  Upgrade = 'Upgrade',
  Other = 'Other',
  InvestmentBalance = 'InvestmentBalance',
  TradeResponseV2 = 'TradeResponseV2',
  PricingTable = 'PricingTable',
  Event = 'Event',
  GeneralInitialNotice = 'GeneralInitialNotice',
  InvestmentAccountSSOIdImport = 'InvestmentAccountSSOIdImport',
}

export enum FileProcessingStatusType {
  UnsupportedFile = 'UnsupportedFile',
  Pending = 'Pending',
  InProcess = 'InProcess',
  Complete = 'Complete',
  CompleteWithErrors = 'CompleteWithErrors',
  Errorred = 'Errorred',
}

export enum FileRequestType {
  NACHA = 'NACHA',
  Check = 'Check',
}

export enum ProcessingType {
  Manual = 'Manual',
  Production = 'Production',
  Testing = 'Testing',
}

export enum ReportType {
  ErrorReport = 'ErrorReport',
  ExceptionReport = 'ExceptionReport',
  RepairFile = 'RepairFile',
}

export enum SeverityLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum SystemReportType {
  ClaimConnexFilter = 'ClaimConnexFilter',
  ClaimConnexEligibility = 'ClaimConnexEligibility',
}

export enum FileExtensionType {
  csv = 'csv',
  json = 'json',
}

export enum UrlType {
  upload = 'upload',
  download = 'download',
}

export enum ClaimConnexMedCode {
  Dental = 'Dental',
  Medical = 'Medical',
  Pharmacy = 'Pharmacy',
}

export enum ClaimConnexType {
  SubstantiationOnly = 'SubstantiationOnly',
  ClaimPayment = 'ClaimPayment',
}

export enum ManualFileType {
  COBRA = 'COBRA',
  ACAReporting = 'ACAReporting',
  Payroll = 'Payroll',
  GiveBack = 'GiveBack',
  TradeFile = 'TradeFile',
  TradeFileV2 = 'TradeFileV2',
  CheckDisbursement = 'CheckDisbursement',
}

export enum ManualFileStatus {
  Received = 'Received',
  Pending = 'Pending',
  Processing = 'Processing',
  Processed = 'Processed',
  ProcessedWithErrors = 'ProcessedWithErrors',
  Error = 'Error',
}

export enum IndividualFields {
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  emailAddress = 'emailAddress',
  phoneNumber = 'phoneNumber',
  address = 'address',
  dateOfBirth = 'dateOfBirth',
  gender = 'gender',
  specifiedGender = 'specifiedGender',
  showPronounsToEmployer = 'showPronounsToEmployer',
  pronouns = 'pronouns',
  specifiedPronouns = 'specifiedPronouns',
  isSmoker = 'isSmoker',
  medicareId = 'medicareId',
  entitlementReason = 'entitlementReason',
}

export enum EmploymentInfoFields {
  employeeId = 'employeeId',
  hireDate = 'hireDate',
  payrollSchedule = 'payrollSchedule',
  class = 'class',
  division = 'division',
  subdivision = 'subdivision',
  terminationDate = 'terminationDate',
  retired = 'retired',
  nonEmployee = 'nonEmployee',
}

export enum CensusThresholdNames {
  newHires = 'newHires',
  terminations = 'terminations',
  addressChanges = 'addressChanges',
  emailChanges = 'emailChanges',
  firstNameChanges = 'firstNameChanges',
  lastNameChanges = 'lastNameChanges',
  hireDateChanges = 'hireDateChanges',
  divisionChanges = 'divisionChanges',
  subdivisionChanges = 'subdivisionChanges',
  classChanges = 'classChanges',
}

export enum CrossroadsThresholdNames {
  newHires = 'newHires',
  addressChanges = 'addressChanges',
  emailChanges = 'emailChanges',
  firstNameChanges = 'firstNameChanges',
  lastNameChanges = 'lastNameChanges',
  hireDateChanges = 'hireDateChanges',
}

export enum EnrollmentThresholdNames {
  newEnrollments = 'newEnrollments',
  benefitAccountTerminations = 'benefitAccountTerminations',
  individualElectionChanges = 'individualElectionChanges',
  clientElectionChanges = 'clientElectionChanges',
  electionChangeDateOver30Days = 'electionChangeDateOver30Days',
}

export enum PvrThresholdNames {
  individualPostingAmountChanges = 'individualPostingAmountChanges',
  clientPostingAmountChanges = 'clientPostingAmountChanges',
  postingAmountChangeToZero = 'postingAmountChangeToZero',
  numberFundingOverrides = 'numberFundingOverrides',
}

export enum TakeoverThresholdNames {
  ytdDisbursementExceedsContribution = 'ytdDisbursementExceedsContribution',
}

export enum DependentThresholdNames {
  lastNameChanges = 'lastNameChanges',
  addressChanges = 'addressChanges',
  emailChanges = 'emailChanges',
  newEnrollments = 'newEnrollments',
  eligibilityEndDateChanges = 'eligibilityEndDateChanges',
}

export enum EventThresholdNames {
  newEvents = 'newEvents',
  genderChanges = 'genderChanges',
  dateOfBirthChanges = 'dateOfBirthChanges',
  nonTerminateEventTypeCodeChanges = 'nonTerminateEventTypeCodeChanges',
}

export enum GeneralInitialNoticeThresholdNames {
  newHires = 'newHires',
  addressChanges = 'addressChanges',
  emailChanges = 'emailChanges',
  firstNameChanges = 'firstNameChanges',
  lastNameChanges = 'lastNameChanges',
  hireDateChanges = 'hireDateChanges',
  divisionChanges = 'divisionChanges',
  subdivisionChanges = 'subdivisionChanges',
  classChanges = 'classChanges',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}
