// AUTOGENERATED BY FUNCTION genubamodel.js
export interface CardCommand extends CommandBase {
  data?: Card;
}

export interface CardPackageCommand extends CommandBase {
  data?: CardPackage;
}

export interface CardPinCommand extends CommandBase {
  data?: CardPin;
}

export interface CardFeeCommand extends CommandBase {
  data?: CardFee;
}

export interface CardActionCommand extends CommandBase {
  data?: CardAction;
}

export interface Card extends EntityBase {
  token?: string;
  last4Digits?: string;
  expiration?: string;
  individualId: string;
  state?: string;
  active?: string;
  orderDateTime?: string;
  issueDateTime?: string;
  cardHolderName?: string;
  mailDate?: string;
  reissueReason?: CardReissueReasonType;
  blockReason?: CardBlockReasonType;
  cardFeeChargeTo?: CardFeeChargeToType;
  cardFeeChargeToBenefitAccountId?: string;
  cardFeeAmount?: number;
  shippingBulkGroupId?: string;
  vendor?: CardVendorType;
  orderedByPlanId?: string;
  financialProcessor?: FinancialProcessor;
  replacedCardId?: string;
}

export interface CardPackage extends EntityBase {
  vendorPackageId: string;
  packageType?: CardPackageType;
  nickName: string;
  attachmentId?: string;
  isDefault: boolean;
}

export interface CardPin extends EntityBase {
  pin: string;
  keyId: string;
}

export interface KeyData {
  keyId: string;
  key: string;
}

export interface CardData {
  cardNumber: string;
  cvc2: string;
  expirationDate: string;
  network: string;
  _retry?: boolean;
}

export interface CardFee extends EntityBase {
  cardFeeType: CardFeeType;
  amount: number;
  waiveFeeStartDate?: string;
  waiveFeeEndDate?: string;
}

export interface CardAction extends EntityBase {
  cardActionType: CardActionType;
  details?: string;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export type SearchCriteria = Criteria[];

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export type Cards = Card[];

export type CardPackages = CardPackage[];

export type CardFees = CardFee[];

export type CardActions = CardAction[];

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum CardCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToBlockedWithFraud = 'ActiveToBlockedWithFraud',
  ActiveToBlockedWithoutFraud = 'ActiveToBlockedWithoutFraud',
  ActiveToExpired = 'ActiveToExpired',
  ActiveToMailed = 'ActiveToMailed',
  ActiveToPendingClosed = 'ActiveToPendingClosed',
  ActiveToReissued = 'ActiveToReissued',
  AnyToError = 'AnyToError',
  BlockedWithoutFraudToActive = 'BlockedWithoutFraudToActive',
  BlockedWithoutFraudToClosed = 'BlockedWithoutFraudToClosed',
  BlockedWithFraudToClosed = 'BlockedWithFraudToClosed',
  EnrichToActive = 'EnrichToActive',
  EnrichToError = 'EnrichToError',
  MailedToActive = 'MailedToActive',
  OrderedToActive = 'OrderedToActive',
  StartToActive = 'StartToActive',
  OrderedToEnrich = 'OrderedToEnrich',
  OrderedToErrorXFAuthFailure = 'OrderedToErrorXFAuthFailure',
  OrderedToErrorXFDuplicate = 'OrderedToErrorXFDuplicate',
  OrderedToErrorXFRetry = 'OrderedToErrorXFRetry',
  PendingClosedToClosed = 'PendingClosedToClosed',
  StartToOrdered = 'StartToOrdered',
  StartToPendingBatchOrder = 'StartToPendingBatchOrder',
  StartToPendingOrder = 'StartToPendingOrder',
  StartToPendingUpgradeOrder = 'StartToPendingUpgradeOrder',
  PendingBatchOrderToOrdered = 'PendingBatchOrderToOrdered',
  PendingOrderToPendingOrder = 'PendingOrderToPendingOrder',
  PendingOrderToOrdered = 'PendingOrderToOrdered',
  PendingOrderToRemoved = 'PendingOrderToRemoved',
  PendingUpgradeOrderToOrdered = 'PendingUpgradeOrderToOrdered',
  PendingUpgradeOrderToPendingUpgradeOrder = 'PendingUpgradeOrderToPendingUpgradeOrder',
  BlockedWithoutFraudToBlockedWithoutFraud = 'BlockedWithoutFraudToBlockedWithoutFraud',
  BlockedWithoutFraudToBlockedWithFraud = 'BlockedWithoutFraudToBlockedWithFraud',
  BlockedWithoutFraudToPendingActive = 'BlockedWithoutFraudToPendingActive',
  PendingActiveToActive = 'PendingActiveToActive',
}

export enum CardPackageCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
}

export enum CardPinCommandType {
  Set = 'Set',
}

export enum CardFeeCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum CardActionCommandType {
  StartToActive = 'StartToActive',
}

export enum CardState {
  Active = 'Active',
  AnyToError = 'AnyToError',
  BlockedWithFraud = 'BlockedWithFraud',
  BlockedWithoutFraud = 'BlockedWithoutFraud',
  Closed = 'Closed',
  Enrich = 'Enrich',
  Error = 'Error',
  Expired = 'Expired',
  Mailed = 'Mailed',
  Ordered = 'Ordered',
  PendingClosed = 'PendingClosed',
  PendingBatchOrder = 'PendingBatchOrder',
  PendingOrder = 'PendingOrder',
  Reissued = 'Reissued',
  Removed = 'Removed',
  Start = 'Start',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  PendingUpgradeOrder = 'PendingUpgradeOrder',
  PendingActive = 'PendingActive',
}

export enum CardPackageState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
  Start = 'Start',
}

export enum CardFeeState {
  Active = 'Active',
  Removed = 'Removed',
  Start = 'Start',
}

export enum CardActionState {
  Active = 'Active',
  Start = 'Start',
}

export enum ParentType {
  INSTANCE = 'INSTANCE',
  CLIENT = 'CLIENT',
  DEPENDENT = 'DEPENDENT',
  INDIVIDUAL = 'INDIVIDUAL',
  CARD = 'CARD',
}

export enum CardPackageType {
  Standard = 'Standard',
  NonStandard = 'NonStandard',
  WhiteLabel = 'WhiteLabel',
}

export enum CardReissueReasonType {
  Damaged = 'Damaged',
  Lost = 'Lost',
  NameChange = 'NameChange',
  NeverReceived = 'NeverReceived',
  Stolen = 'Stolen',
  SuspectedFraud = 'SuspectedFraud',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum CardBlockReasonType {
  ParticipantRequested = 'ParticipantRequested',
  ClientRequested = 'ClientRequested',
  MyCashNegativeBalance = 'MyCashNegativeBalance',
  UnresolvedClaimOrBalanceIssue = 'UnresolvedClaimOrBalanceIssue',
  Divorce = 'Divorce',
  Other = 'Other',
  Fraud = 'Fraud',
  SuspiciousActivity = 'SuspiciousActivity',
  CardReturnedUndeliverable = 'CardReturnedUndeliverable',
  InactiveAccount = 'InactiveAccount',
  Migration = 'Migration',
}

export enum CardFeeType {
  FirstPrimaryCard = 'FirstPrimaryCard',
  FirstDependentCard = 'FirstDependentCard',
  SecondDependentCard = 'SecondDependentCard',
  ThirdDependentCard = 'ThirdDependentCard',
  FourthDependentCard = 'FourthDependentCard',
  FifthDependentCard = 'FifthDependentCard',
  AdditionalDependentCard = 'AdditionalDependentCard',
  CardReissue = 'CardReissue',
}

export enum CardFeeChargeToType {
  Client = 'Client',
  ParticipantIab = 'ParticipantIab',
  ParticipantBenefitAccount = 'ParticipantBenefitAccount',
}

export enum CardActionType {
  CardBlocked = 'CardBlocked',
  CardReissued = 'CardReissued',
  CardUnblocked = 'CardUnblocked',
  CardIssued = 'CardIssued',
  PINSet = 'PINSet',
  AccessSet = 'AccessSet',
}

export enum CardVendorType {
  ARROWEYE = 'ARROWEYE',
  ABCORP = 'ABCORP',
  DIGITAL_ONLY = 'DIGITAL_ONLY',
}

export enum FinancialProcessor {
  XPS1 = 'XPS1',
  XPS2 = 'XPS2',
}
