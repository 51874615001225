export interface ChangePasswordResult {
  additionalInfo?: string;
  error?: Error;
  type: ChangePasswordResultType;
}

export enum ChangePasswordResultType {
  Failed = 'Failed',
  IncorrectCurrentPassword = 'IncorrectCurrentPassword',
  InvalidDesiredPassword = 'InvalidDesiredPassword',
  Success = 'Success',
  UnhandledErrorCode = 'UnhandledErrorCode',
  Unsupported = 'Unsupported',
}
