import { isPlatformBrowser } from '@angular/common';

export abstract class BrowserStorageService {
  private _isRenderingInBrowser: boolean;

  public constructor(
    platformId: object,
    private storageType: Storage,
  ) {
    this._isRenderingInBrowser = isPlatformBrowser(platformId);
  }

  public get<T>(key: string): T {
    if (!this._isRenderingInBrowser) {
      return null;
    }
    return JSON.parse(this.storageType.getItem(key));
  }

  public remove(key: string): void {
    if (!this._isRenderingInBrowser) {
      return;
    }
    this.storageType.removeItem(key);
  }

  public set<T>(key: string, value: T): void {
    if (!this._isRenderingInBrowser) {
      return;
    }
    this.storageType.setItem(key, JSON.stringify(value));
  }
}
