import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Document } from 'src/app/shared/models/uba/profileConfiguration/model';

export interface TermsOfUseState extends EntityState<Document, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'TermsOfUse' })
export class TermsOfUseStore extends EntityStore<TermsOfUseState> {
  public constructor() {
    super();
  }
}
